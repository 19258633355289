import React, { useEffect, useRef } from 'react';
import backgroundVideo from '../Videos/backgroundVideo.mp4';
import '../CSS/BackGroundVid.css'

function BackGroundVideo() {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.load();
      const playPromise = videoElement.play();

      
      if (playPromise !== undefined) {
        playPromise.catch((error) => {
          console.error('Video playback failed:', error);
        });
      }
    }

    const handleTouchStart = () => {
      if (videoRef.current && videoRef.current.paused) {
        videoRef.current.play();
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (videoRef.current && videoRef.current.paused) {
          videoRef.current.play();
        }
      }
    };

    window.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);



  return (
    <div style={{ height: '100%', width: '100%' }}>
      <video
        ref={videoRef}
        src={backgroundVideo}
        autoPlay
        muted
        loop
        playsInline
        preload='auto'
        style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            minHeight: '100vh', 
        }}
      />
      <div className="title">
        <div className="left">
            <div className="large-text">Artisans peintres</div>
            <div className="small-text">en</div>
            <div className="large-text">
                <span className="charente">Charente</span>
                <span className="maritime">-Maritime</span>
            </div>
            <div className="small-text">à</div>
            <div className="large-text">
                <span className="la">La</span>
                <span className="rochelle"> Rochelle</span>
            </div>
            <div className="small-text">et sur</div>
            <div className="large-text">l'Île de Ré</div>
        </div>
                <div className="right">
                  <p>
                    Les travaux de peint&shy;ure en Charente-maritime à La Roche&shy;lle et sur l'Île de Ré donn&shy;eront une nouv&shy;elle vie à votre mai&shy;son,
                    appart&shy;ement ou local comm&shy;ercial. Que ce soit pour une réno&shy;vation,
                    une constru&shy;ction ou un réaména&shy;gement, les coule&shy;urs chois&shy;ies peu&shy;vent
                    complèt&shy;ement chang&shy;er l'amb&shy;iance de votre espa&shy;ce de vie. Notre équ&shy;ipe de professi&shy;onnels
                    de la peintu&shy;re en Charente-maritime à La Rochel&shy;le et sur l'Île de Ré vous aide à choi&shy;sir les
                    couleu&shy;rs idéale&shy;s pour votre proj&shy;et. Contactez-nous pour des trav&shy;aux de pein&shy;ture réussis.
                  </p>
                </div>
            </div>
        </div>
    );
}

export default BackGroundVideo;

