import React from 'react';
import '../CSS/Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {

  

    return (
      <div className="footer-links-container">
        <div className="footer-links">
        <Link to="/legal-notice" className="footer-link" >Politique de confidentialité et Mentions légales</Link>
        </div>
      </div>
    );
};

export default Footer;