import React from 'react';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import FormatPaintRoundedIcon from '@mui/icons-material/FormatPaintRounded';
import '../CSS/Header.css';

const Header = () => {
  const [isHeaderFixed, setIsHeaderFixed] = React.useState(false);

  React.useEffect(() => {
    const handleWindowScroll = () => {
      setIsHeaderFixed(window.pageYOffset > 0);
    };

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  const handleContact = () => {
    window.location.hash = "#form";
  };

  const handleRealisation = () => {
    window.location.hash = "#realisations";
  };

  return (
    <header className={`header ${isHeaderFixed ? 'fixed' : ''}`}>
      <div className="header-left">
        <div className="logo">
          <LocalPhoneRoundedIcon className="icon" />
          <p className='phone'>06.24.82.68.06</p>
        </div>
      </div>
      <div className="header-center">
        <div className="contact">
          <EmailRoundedIcon className="icon" />
          <a href="#form" onClick={handleContact}>Contactez-nous</a>
        </div>
      </div>
      <div className="header-right">
        <div className="realisations">
          <FormatPaintRoundedIcon className="icon" />
          <a href="#realisations" onClick={handleRealisation}>Réalisations</a>
        </div>
      </div>
    </header>
  );
};

export default Header;
