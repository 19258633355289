import React from 'react';
import loadable from '@loadable/component';
import Header from './Components/Header';
import Footer from './Components/Footer';
import UpperFooter from './Components/UpperFooter';
import './CSS/App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BackGroundVideo from './Components/BackgroundVideo';
import LegalNotice from './Components/LegalNotice';


const MainPageLoadable = loadable(() => import('./Components/MainPage'));

function App() {
  return (
    <Router>
      <div className="App">
        <BackGroundVideo className='video'/>
        <Header/>
        <Routes>
          <Route exact path="/" element={<MainPageLoadable/>} />
          <Route path="/legal-notice" element={<LegalNotice/>} />
        </Routes>
        <UpperFooter />
        <Footer />
      </div>
    </Router>
  );
}

export default App;